



console.log('-- App.js --')



function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}


docReady(function() {
  console.log('Doc ready')

  //require('./scroll/scroll.js');
  require('./lazyload/lazysizes.js');
  require('./gsap/topbar.js');
  require('./gsap/fadein.js');
  require('./anim.js');


});









// var iconLook = bodymovin.loadAnimation({
//   container: document.getElementById('icon-look'),
//   renderer: 'svg',
//   loop: true,
//   autoplay: false,
//   path: './js/bodymovin/icon-look.json'
// })
//
// document.getElementById('btn-look').addEventListener('mouseover', function(){ iconLook.play(), iconLook.setDirection(1); })
//







// Btn animations
// +++++++++++++++++++++++++++++++++++++++++++++++++++
//
// const btntext = document.getElementsByClassName('animtext');
//
// gsap.set(".aomicon", {scale: 0});
//
// gsap.utils.toArray(".btn-anim").forEach(container => {
//   let icon = container.querySelector(".aomicon"),
//       text = container.querySelector(".animtext"),
//       iris = container.querySelector("#iris"),
//       circle = container.querySelector("#bg"),
//       tl = gsap.timeline({ paused: true });
//
//   //gsap.set(iris, {x:-2});
//
//   tl.to(icon, {scale: 2, duration: 0.5, ease: "power3"});
//   tl.to(circle, {duration: 1, fill:"#FDC00E", transformOrigin: "center center", ease: "power3"}, 0);
//   tl.to(text, {scale:0.6, y:+60, duration: 0.5, ease: "power3"}, 0);
//   //tl.to(iris, {x:+3, duration: 0.5, ease: "sineInOut"}, 0.3);
//
//   container.addEventListener("mouseenter", () => tl.play() );
//   container.addEventListener("mouseleave", () => tl.reverse() );
// });
