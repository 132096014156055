import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


const topbar = document.getElementById('topbar');

ScrollTrigger.create({
  trigger: "header",
  start: "top+=20 top",
  //markers:true,
  onEnter:() => topbar.classList.add("is-active"),
  onLeaveBack:() => topbar.classList.remove("is-active"),
});


// Get all dropdowns on the page that aren't hoverable.
const hammburger = document.querySelector('.mobile-icon');
const mobileMenu = document.querySelector('.mobile-menu');


hammburger.addEventListener("click", function(event) {
  event.preventDefault();
  mobileMenu.classList.toggle("is-active");
  hammburger.classList.toggle("is-active");
});


console.log('--topbar')
