
//import lottie from 'lottie-web/build/player/lottie_svg.min.js'

// This one
// import lottie from 'lottie-web/build/player/lottie_light.min.js'


// console.log('anim load ---')

// https://airbnb.io/lottie/#/web?id=usage
// https://useanimations.com/
// https://thesvbd.github.io/Lottie-examples/#top


// This one
// var animation = lottie.loadAnimation({
//   container: document.getElementById('bm'),
//   renderer: 'svg',
//   loop: false,
//   autoplay: false,
//   path: 'theme/js/bodymovin/icon-play.json'
// })


// var bm = document.getElementById('bm');
// bm.addEventListener('mouseover', () => {
//   animation.setDirection(1);
//   animation.play();
// });
// bm.addEventListener('mouseout', () => {
//   animation.setDirection(-1);
//   animation.play();
// });
//







// document.querySelector('.animated-button a').addEventListener("mouseover", () =>
//   //animation.playSegments([9,80],true)
//   animation.play()
// );
// document.querySelector('.animated-button a').addEventListener("mouseout", () =>
//   //animation.playSegments([9,80],true)
//   animation.goToAndStop(1)
// );


//document.querySelector('.animated-button a').addEventListener('mouseover', function(){ animation.play(), animation.playSegments([9,80]), animation.setDirection(1); })
//document.querySelector('.animated-button a').addEventListener('mouseout', function(){ animation.goToAndStop(1), console.log('out') })
//


//
// animation.addEventListener("complete", () =>
//   console.log('complete'),
//   animation.play()
// );
//




//
// let iconMenu = document.querySelector('#bm');
//
// let animationMenu = lottie.loadAnimation({
//         container: iconMenu,
//         renderer: 'svg',
//         loop: false,
//         autoplay: false,
//         path: "https://raw.githubusercontent.com/thesvbd/Lottie-examples/master/assets/animations/menu.json"
// });
//
//
// var directionMenu = 1;
//   iconMenu.addEventListener('click', (e) => {
//   animationMenu.setDirection(directionMenu);
//   animationMenu.play();
//   directionMenu = -directionMenu;
// });





//Play an animation back on second click
//
// var container = document.getElementById('bm');
// var state = 'play';
//
// var animation = bodymovin.loadAnimation({
//   container: container,
//   path: './js/bodymovin/webdev.json',
//   renderer: 'svg',
//   loop: false,
//   autoplay: false,
//   name: "Demo Animation",
// });
//
// // animation.goToAndStop(14, true);
// var row = document.getElementById('row');
// row.addEventListener('mouseover', () => {
//
//   animation.play();
//
//   // if(state === 'play') {
//   //  animation.playSegments([14, 27], true);
//   //   state = 'pause';
//   // } else {
//   //   animation.playSegments([0, 14], true);
//   //   state = 'play';
//   // }
// });








//
//
// function LottieScrollTrigger(vars) {
// 	let playhead = {frame: 0},
// 		target = gsap.utils.toArray(vars.target)[0],
// 		speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
// 		st = {trigger: target, end: speeds[vars.speed] || "+=1000", scrub: 1},
// 		animation = lottie.loadAnimation({
// 			container: target,
// 			renderer: vars.renderer || "svg",
// 			loop: false,
// 			autoplay: false,
// 			path: vars.path
// 		});
// 	for (let p in vars) { // let users override the ScrollTrigger defaults
// 		st[p] = vars[p];
// 	}
// 	animation.addEventListener("DOMLoaded", function() {
// 		gsap.to(playhead, {
// 			frame: animation.totalFrames - 1,
// 			ease: "none",
// 			onUpdate: () => animation.goToAndStop(playhead.frame, true),
// 			scrollTrigger: st
// 		});
//     // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
//     ScrollTrigger.sort();
//     ScrollTrigger.refresh();
// 	});
//   return animation;
// }
//
//

// LottieScrollTrigger({
//  target: "#bm1",
//  path: "./js/bodymovin/webdev.json",
//  speed: "medium",
//  scrub: 1 ,// seconds it takes for the playhead to "catch up",
//  pin: false,
//  // start: "top 20%",
//  // end:"center",
//  markers:true,
//  // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See https://greensock.com/docs/v3/Plugins/ScrollTrigger
// });
//
