import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


gsap.utils.toArray('.section').forEach(section => {


  // Fade in elements

  // gsap.fromTo(section, {
  //   opacity: 0
  // },{
  //   opacity: 1,
  //   scrollTrigger: {
  //     trigger: section,
  //     start: "center 80%",
  //     end: "center center",
  //     //scrub: true,
  //   markers: true,
  //   }
  // })




});
